import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_URL1 } from "../services/url";
import "./Login.css";
import logo from "../images/Logo3.PNG";
import { useAlert } from "react-alert";
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [btn_Status, setbtn_Status] = useState("unclick");
  const alert = useAlert();
  const data = {
    email,
    password,
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setbtn_Status("clicked");
    axios
      .post(`${API_URL1}trucker/login`, data)

      .then((response) => {
        if (response.data.success === true) {
          alert.success(response.data.message);

          localStorage.setItem("TruckertokenKey", response.data.token);
          localStorage.setItem("adminid", response.data.user.truck_company_id);
          localStorage.setItem("adminname", response.data.user.name);
          localStorage.setItem("Temail", response.data.user.email);
          localStorage.setItem("phone_no", response.data.user.phone_no);
          localStorage.setItem(
            "trucker_id",
            response.data.user.truck_company_user_id
          );
          localStorage.setItem("company_name", response.data.user.company_name);
          window.location.reload();
          window.location = "/";
          return <div />;
        } else {
          alert.error("Invalid Email or Password", {
            onClose: () => {
              window.location.reload();
            },
          });
        }
      })
      .catch((error) => {
        alert.error("Invalid Email or Password", {
          onClose: () => {
            window.location.reload();
          },
        });
        console.error(error);
      });
  };

  return (
    <div className="login_top">
      <Navbar style={{ backgroundColor: "black" }}>
        <Navbar.Brand>
          <Link to="/">
            <img
              src={logo}
              style={{ width: "12rem" }}
              className="d-inline-block align-top"
              alt=""
            />
          </Link>
        </Navbar.Brand>
      </Navbar>
      <div className="my-login-page" style={{ backgroundColor: "black" }}>
        <div className="container">
          <div
            className="row justify-content-center signup_div h-100 w-100"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div
              className="col-lg-6 pt-5 top signup_div"
              style={{ backgroundColor: "black" }}
            >
              <h4
                className="card-title text-light"
                align="center"
                style={{ fontSize: "30px" }}
              >
                Sign In
              </h4>
              <form
                method="POST"
                onSubmit={(e) => onSubmit(e)}
                className="pt-3 pl-5 ml-5 col-lg-10"
              >
                <div className="input-group input-group-lg mt-3 ">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text bg-white border-0 rounded-1-left pr-1"
                      id="inputGroup-sizing-lg"
                    >
                      <div className="fas fa-envelope" aria-hidden="true"></div>
                    </span>
                  </div>
                  <input
                    type="text"
                    name="email"
                    className="form-control border-0 rounded-1-right pl-3 bg-white"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-lg"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value.toLowerCase())}
                  />
                </div>

                <div className="input-group input-group-lg mt-3 pt-3">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text bg-white border-0 rounded-1-left pr-1"
                      id="inputGroup-sizing-lg"
                    >
                      <div className="fas fa-lock" aria-hidden="true"></div>
                    </span>
                  </div>
                  <input
                    id="password"
                    type="password"
                    name="password"
                    className="form-control border-0 rounded-1-right align-middle pl-3 bg-white"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-lg"
                    placeholder="Password"
                    required=""
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="form-group no-margin pt-5 pb-5">
                  <button
                    type="submit"
                    rows="5"
                    className="button button2 btn-block"
                    disabled={btn_Status === "clicked"}
                    style={{
                      backgroundColor: "rgb(42,112,125)",
                      fontSize: "20px",
                    }}
                  >
                    Login
                  </button>
                </div>
                <div style={{ marginBottom: "1px" }}>
                  <hr style={{ backgroundColor: "white" }} />
                </div>
                <div className="d-flex flex-row justify-content-between">
                  <Link
                    style={{
                      fontSize: "20px",
                      fontStyle: "Italic",
                      color: "white",
                    }}
                    to="/register"
                  >
                    <p>Sign up</p>
                  </Link>
                  <Link
                    style={{
                      fontSize: "20px",
                      fontStyle: "Italic",
                      color: "white",
                    }}
                    to="/forgetpass"
                  >
                    <p>Forgot Password</p>
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
